import { graphql, useStaticQuery } from "gatsby"
import React, { useRef, useState, useEffect } from "react"
import OfferForm from "../../OfferForm"
import Button from "../../UI/Button"
import Container from "../../UI/Container"
import Modal from "../../UI/Modal/Index"
import useModal from "../../UI/Modal/useModal"
import { useAppDispatch, useAppState } from "../../../store/context/app-context"
import WindowSizeListener from "react-window-size-listener"
import {
  Advice,
  Advices,
  Bottom,
  Content,
  FeebackPseudo,
  Feedback,
  FeedbackWrapper,
  InnerWrapper,
  Logo,
  Logos,
  Title,
  Wrapper,
} from "./styles"
import useScrollPosition from "../../../utils/useScrollPosition"
import useElementScrollPosition from "../../../utils/useElementScrollPosition"
import { FeedbackTitle } from "../Catering/styles"

const Consulting = () => {
  const { isShowing, setIsShowing, toggle } = useModal()
  const ref = useRef()
  const elRef = useRef()
  const dispatch = useAppDispatch()
  useElementScrollPosition(response => {
    dispatch({
      type: "SET_POSITION",
      payload: response.position,
    })
  }, elRef)

  const data = useStaticQuery(graphql`
    query {
      wpPage(slug: { in: "noustamine" }) {
        blocks {
          name
          originalContent
        }
      }
      allWpReview {
        edges {
          node {
            content
            tags {
              nodes {
                slug
                id
              }
            }
            blocks {
              originalContent
              name
            }
          }
        }
      }
      allWpNouanne {
        edges {
          node {
            title
            content
            id
          }
        }
      }
      allWpLogo {
        nodes {
          id
          featuredImage {
            node {
              id
              sourceUrl
            }
          }
        }
      }
    }
  `)

  let i = 0

  return (
    <Wrapper>
      <InnerWrapper>
        <Container>
          <Title>
            {data.wpPage.blocks.map(block => {
              if (block.name === "core/heading") {
                i++
                return (
                  <div
                    key={i}
                    dangerouslySetInnerHTML={{
                      __html: block.originalContent,
                    }}
                  />
                )
              }
            })}
          </Title>
          <Content>
            {data.wpPage.blocks.map(block => {
              if (block.name === "core/paragraph") {
                i++
                return (
                  <div
                    key={i}
                    dangerouslySetInnerHTML={{ __html: block.originalContent }}
                  />
                )
              }
            })}
          </Content>
        </Container>
      </InnerWrapper>
      <Bottom>
        <div ref={elRef}>
          <Container>
            <Advices>
              {data.allWpNouanne.edges.map(edge => {
                i++
                return (
                  <Advice key={edge.node.id}>
                    <FeebackPseudo className="pseudo" key={i + 10} />
                    <h3>{edge.node.title}</h3>
                    <div
                      key={i}
                      dangerouslySetInnerHTML={{ __html: edge.node.content }}
                    />
                  </Advice>
                )
              })}
            </Advices>
            <Logos>
              {data.allWpLogo.nodes.map(node => {
                return (
                  <Logo key={node.id}>
                    <img src={node.featuredImage.node.sourceUrl} alt="" />
                  </Logo>
                )
              })}
            </Logos>
            <FeedbackWrapper>
              {data.allWpReview.edges.map(edge => {
                i++
                return edge.node.tags.nodes.map(tag => {
                  if (tag.slug === "noustamine") {
                    return (
                      <Feedback key={tag.id}>
                        {edge.node.blocks.map(block => {
                          if (block.name === "core/paragraph") {
                            return (
                              <div
                                key={i}
                                dangerouslySetInnerHTML={{
                                  __html: block.originalContent,
                                }}
                              />
                            )
                          } else if (block.name === "core/heading") {
                            return (
                              <FeedbackTitle>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: block.originalContent,
                                  }}
                                />
                              </FeedbackTitle>
                            )
                          }
                        })}
                      </Feedback>
                    )
                  }
                })
              })}
            </FeedbackWrapper>
            <Button
              onClick={toggle}
              title="Võta ühendust"
              size="large"
              color="light"
              centered
            />
            <Modal
              setIsShowing={setIsShowing}
              isShowing={isShowing}
              toggle={toggle}
              ref={ref}
              background="light"
            >
              <OfferForm />
            </Modal>
          </Container>
        </div>
      </Bottom>
    </Wrapper>
  )
}

export default Consulting
