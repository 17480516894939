import styled, { keyframes } from "styled-components"
import kaas from "../../../assets/images/kaas2.png"

export const Wrapper = styled.div`
  position: relative;
  align-items: center;
  overflow: hidden;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: -100px;
    width: 300px;
    height: 310px;
    background-image: url(${kaas});
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 0;
  }
  @media (min-width: 1024px) {
    &:after {
      right: 25px;
      height: 380px;
      width: 345px;
    }
  }
  @media only screen and (min-width: 1200px) {
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 50px;
      width: 470px;
      height: 550px;
    }
  }
  @media only screen and (min-width: 1300px) {
    &:after {
      right: 50px;
      width: 510px;
      height: 550px;
    }
  }
  @media only screen and (min-width: 1400px) {
    &:after {
      right: 125px;
      width: 550px;
      height: 570px;
    }
  }
  @media only screen and (min-width: 1500px) {
    &:after {
      right: 150px;
      width: 590px;
      height: 610px;
    }
  }
  @media only screen and (min-width: 1600px) {
    &:after {
      right: 110px;
      width: 617px;
      height: 638px;
    }
  }
  @media only screen and (min-width: 1700px) {
    &:after {
      right: 180px;
    }
  }
  @media only screen and (min-width: 1800px) {
    &:after {
      right: 200px;
    }
  }
  @media only screen and (min-width: 1900px) {
    &:after {
      right: 350px;
    }
  }
`

export const InnerWrapper = styled.div`
  z-index: 2;
  min-height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  margin-top: auto;
  width: 100%;
  padding-top: 4rem;
  @media only screen and (min-width: 1200px) {
    padding-top: 0;
    margin-bottom: 0;
    height: 100vh;
    min-height: 700px;
  }
`

export const Title = styled.div`
  z-index: 2;
  h2 {
    color: #e2c6a9;
    font-family: "JosefinSans-Bold";
    font-weight: 700;
    font-size: 31px;
  }
  @media (min-width: 768px) {
    h2 {
      font-size: 44px;
      max-width: 600px;
    }
  }
  @media only screen and (min-width: 1200px) {
    padding-top: 10rem;
    h2 {
      font-size: 80px;
      line-height: 85px;
      margin: 0;
      margin-bottom: 3rem;
      max-width: 760px;
    }
  }
`
export const Content = styled.div`
  max-width: 580px;
  h3 {
    color: #fff;
    font-family: "JosefinSans-Regular";
  }
  p {
    color: #fff;
    font-family: "JosefinSans-Regular";
  }
  ul {
    li {
      color: #fff;
      font-family: "JosefinSans-Regular";
    }
  }
  @media only screen and (min-width: 1200px) {
    max-width: 535px;
    p {
      font-size: 18px;
      line-height: 35px;
    }
  }
`

export const Bottom = styled.div`
  background-color: #1e2123;
  padding-bottom: 5rem;
`

export const Advices = styled.div`
  padding-top: 3rem;
  @media (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  @media only screen and (min-width: 1200px) {
    padding-top: 5rem;
  }
`

export const Advice = styled.div`
  margin-bottom: 3.5rem;
  h3 {
    font-family: "JosefinSans-Regular";
    color: #e2c6a9;
    font-weight: 400;
    margin-bottom: 0.75rem;
    line-height: 30px;
    font-size: 26px;
  }
  p {
    font-family: "JosefinSans-Regular";
    color: #fff;
    line-height: 20px;
  }

  @media (min-width: 768px) {
    flex: 0 0 48%;
  }
  @keyframes joon {
    0% {
      &:after {
        height: 0;
      }
      &:before {
        width: 0;
      }
    }
    25% {
      &:after {
        height: 100%;
      }
      &:before {
        width: 0%;
      }
    }
    50% {
      &:before {
        width: 100%;
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    flex: 0 0 28%;
    margin: 2.5rem 0;
    padding: 1rem;
    h3 {
      font-size: 28px;
      margin-bottom: 1.5rem;
    }
    p {
      font-size: 18px;
      max-width: 300px;
      line-height: 24px;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 1px;
      height: 0;
      background-color: #e2c6a9;
    }
    &:before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 0px;
      height: 1px;
      background-color: #e2c6a9;
    }
    &:hover {
      animation: joon 1s;
    }
  }
  @media only screen and (min-width: 1400px) {
    flex: 0 0 26%;
  }
`

export const Logos = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export const Logo = styled.div`
  flex: 0 0 40%;
  display: flex;
  justify-content: center;
  margin: 0.75rem;
  img {
    width: 90px;
  }
  @media (min-width: 768px) {
    flex: 0 0 20%;
  }
  @media (min-width: 1024px) {
    flex: 0 0 15%;
    img {
      width: 110px;
    }
  }
`

export const FeedbackWrapper = styled.div`
  padding: 2rem 0;
  margin-bottom: 3rem;
  @media (min-width: 1024px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @media only screen and (min-width: 1200px) {
    margin-bottom: 4rem;
    justify-content: center;
  }
`

export const FeebackPseudo = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  pointer-events: none;
`

export const Feedback = styled.div`
  position: relative;
  padding: 1rem;
  border: 1px solid #707070;
  border-radius: 0.5rem;
  margin: 1rem 0;
  p {
    font-family: "JosefinSans-Regular";
    color: #e2c6a9;
  }
  @keyframes {
  }
  @media (min-width: 1024px) {
    width: 48%;
  }
  @media only screen and (min-width: 1200px) {
    width: 425px;
    padding: 2rem;
    margin: 0 1rem;
    &:hover {
    }
    p {
      margin: 0;
      font-size: 25px;
      line-height: 30px;
    }
  }
`
