import React from "react"
import Consulting from "../components/Pages/Consulting"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"

export default function Noustamine({ data }) {
  const page = graphql`
    query {
      wpPage(slug: { in: "noustamine" }) {
        title
      }
    }
  `

  return (
    <Layout background="green" gradient="green">
      <SEO title="Nõustamine" />
      <Consulting title={page.wpPage?.title} />
    </Layout>
  )
}
